import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Create from "./pages/Create";
import { Container, Box } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { SignInButton } from "./components/Buttons/SignInButton";
import { Route, Routes } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import Edit from "./pages/Edit";
import SnackbarComponent from "./components/Snackbar";
import { SnackbarProvider } from "./providers/SnackbarProvider";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const AuthHome = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <Home />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Container sx={{ mt: (theme) => theme.spacing(5) }}>
          <Box mx={2}>
            <h5 className="card-title">Please sign-in to access.</h5>
          </Box>
          <SignInButton variant="contained" />
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

const AuthCreate = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <Create />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Container sx={{ mt: (theme) => theme.spacing(5) }}>
          <Box mx={2}>
            <h5 className="card-title">Please sign-in to access.</h5>
          </Box>
          <SignInButton variant="contained" />
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <>
      <SnackbarProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<AuthHome />} />
            <Route path="/create" element={<AuthCreate />} />
            <Route path="/edit/:id" element={<Edit />} />
          </Routes>
          <SnackbarComponent />
        </Layout>
      </SnackbarProvider>
    </>
  );
}
