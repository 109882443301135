import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

interface SignInButtonProps {
  className?: string;
}

export const SignInButton: React.FC<SignInButtonProps> = ({
  className = "",
}) => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e: any) => {
        console.error("Login failed", e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e: any) => {
        console.error("Login failed", e);
      });
    }
  };

  return (
    <button
      className={`rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white hover:bg-gray-700 hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 ${className}`}
      onClick={() => handleLogin("redirect")}
    >
      Sign In
    </button>
  );
};
