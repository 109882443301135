import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import App from "./App.jsx";
import "./globals.css";
import "./tailwind.css";
import "./styles.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";
import { UIContextProvider } from "./providers/UIProvider";

// Initialize the PublicClientApplication instance
export const msalInstance = new PublicClientApplication(msalConfig as any);

// Use createRoot to render the app in React 18
const container = document.getElementById("root"); // Get the container to render the React app
const root = createRoot(container as HTMLElement); // Create a root

root.render(
  // Use the render method of the root
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <UIContextProvider>
          <App />
        </UIContextProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);
