import React, { useContext } from "react";
import { UIContext } from "../../providers/UIProvider";
import logo from "../../assets/images/logo-white.png";
import { Link } from "react-router-dom";

type Props = {
  headerRight: React.ReactNode;
};

const Header = ({ headerRight }: Props) => {
  const [state]: any = useContext(UIContext);

  return (
    <nav className="bg-primary-500">
      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-start">
            <div className="flex flex-shrink-0 items-center">
              <Link to="/">
                <img src={logo} className="h-12 w-auto" alt="logo" />
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center h-full">
              <h1 className="text-xl font-semibold text-white">
                {state.navbar.title}
              </h1>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {headerRight}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
