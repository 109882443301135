import * as React from "react";
// @ts-ignore
import axiosInstance from "../../utils/axiosInstance";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSnackbarContext } from "../../providers/SnackbarProvider";
import { generatePDF } from "../../utils/generatePDF";
import {
  ChevronRightIcon,
  HomeIcon,
  PaperClipIcon,
  PlusIcon,
  UserCircleIcon,
  PrinterIcon as PrintIcon,
  ChevronLeftIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { useDropzone } from "react-dropzone";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

enum Status {
  NotReceivedYet = "Not received yet",
  ReceivedWithErrors = "Received with errors",
  Received = "Received",
  Closed = "Closed",
}

const statusOptions = [
  {
    value: Status.NotReceivedYet,
    text: Status.NotReceivedYet,
  },
  {
    value: Status.ReceivedWithErrors,
    text: Status.ReceivedWithErrors,
  },
  {
    value: Status.Received,
    text: Status.Received,
  },
  {
    value: Status.Closed,
    text: Status.Closed,
  },
];

export default function Edit() {
  const { accounts } = useMsal();
  const location = useLocation();
  const { showSnackbar } = useSnackbarContext();
  const pathParts = location.pathname.split("/"); // Split the pathname by '/'
  const id = pathParts[pathParts.length - 1];
  const account = useAccount(accounts[0]);
  const [PDFData, setPDFData] = useState<any>();
  const [showMaxCharWarning, setShowMaxCharWarning] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formState, setFormState] = useState<{
    region: string;
    type: string;
    projectNumber: string;
    projectName: string;
    section: string;
    brixProjectId: string;
    guaranteeFileNumber: string;
    status: string;
    addresses: {
      id: string;
      takedownId: number;
      address: string;
      block: string;
      price: string;
      lot: string;
      jobNumber: string;
      modifiedBy: string;
    }[];
  }>({
    region: "",
    type: "",
    projectNumber: "",
    projectName: "",
    section: "",
    guaranteeFileNumber: "",
    brixProjectId: "",
    status: "",
    addresses: [],
  });

  const [addressState, setAddressState] = useState({
    address: "",
    takedownId: id,
    lot: "",
    block: "",
    price: "",
    jobNumber: "",
    modifiedBy: account?.name || "",
  });
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = React.useState<
    {
      comment: string;
      createdBy: string;
      createdDatetime: string;
      id: number;
      isActive: boolean;
      modifiedBy: string;
      modifiedDatetime: string;
      takedownId: number;
    }[]
  >([]);
  const [attachments, setAttachments] = React.useState<
    {
      attachmentName: string;
      createdBy: string;
      createdDatetime: string;
      id: number;
      isActive: boolean;
      modifiedBy: string;
      modifiedDatetime: string;
      sasUrl: string;
      takedownId: number;
    }[]
  >([]);
  const [jobNumbers, setJobNumbers] = React.useState<
    {
      text: string;
      value: string;
    }[]
  >([]);
  const [initialValues, setInitialValues] = useState<{
    guaranteeFileNumber: string;
    status: string;
  }>({
    guaranteeFileNumber: "",
    status: "",
  });
  const [activeTab, setActiveTab] = useState<string>("Comments");
  const [currentCommentPage, setCurrentCommentPage] = useState(1);
  const [currentAttachmentPage, setCurrentAttachmentPage] = useState(1);
  const itemsPerPage = 5;

  const addComment = () => {
    if (!newComment.length) return;
    saveComment();
  };

  function modifyArray(array: any[]): any[] {
    array.forEach((item) => {
      if (item.hasOwnProperty("address")) {
        item.addressStreet = item["address"];
        delete item["address"];
      }
      if (item.id === "NEW_ADDRESS") {
        delete item.id; // Remove the id property
        item.createdby = account?.name || ""; // Add the createdby property
      }
    });
    return array;
  }

  const updateTakedown = async () => {
    setLoading(true);
    try {
      const updatedTakedownData = {
        id: parseInt(id),
        guaranteeFileNumber: formState.guaranteeFileNumber,
        titleStatusId: (() => {
          switch (formState.status) {
            case Status.NotReceivedYet:
              return 1;
            case Status.ReceivedWithErrors:
              return 3;
            case Status.Closed:
              return 4;
            default:
              return 2;
          }
        })(),
        modifiedBy: account?.name || "",
      };

      const response = await axiosInstance.post(
        `/api/UpdateTakedown`,
        updatedTakedownData
      );

      if (response.status === 200) {
        showSnackbar("Updated takedown successfully", "success");
        await loadDetails(id); // Refetch the data
      } else {
        showSnackbar("There was an issue updating the takedown", "error");
      }
    } catch (error) {
      console.error("Error updating takedown:", error);
      showSnackbar("An error occurred while updating the takedown", "error");
    }
    setLoading(false);
  };

  const loadDetails = (id: string) => {
    //setLoading(true);
    axiosInstance
      .get(`/api/GetTakedownDetails/${id}`)
      .then((res) => {
        setFormState({
          region: res.data.results.region,
          type: res.data.results.type,
          projectNumber: res.data.results.projectNumber,
          projectName: res.data.results.projectName,
          section: res.data.results.section,
          guaranteeFileNumber: res.data.results.guaranteeFileNumber,
          brixProjectId: res.data.results.brixProjectId,
          status: res.data.results.status,
          addresses: res.data.results.takedownLots
            .filter((lot: any) => lot.isActive)
            .map(
              (lot: {
                id: number;
                takedownId: number;
                addressStreet: any;
                lot: any;
                block: any;
                price: any;
                modifiedBy: string;
                jobNumber: any;
              }) => ({
                id: lot.id,
                takedownId: lot.takedownId,
                address: lot.addressStreet,
                lot: lot.lot,
                block: lot.block,
                jobNumber: lot.jobNumber,
                price: lot.price,
                modifiedBy: account?.name || "",
              })
            ),
        });
        setInitialValues({
          guaranteeFileNumber: res.data.results.guaranteeFileNumber,
          status: res.data.results.status,
        });
        setAttachments(
          res.data.results.takedownAttachments.filter(
            (c: { isActive: boolean }) => c.isActive === true
          )
        );
        setComments(
          res.data.results.takedownComments.filter(
            (c: { isActive: boolean }) => c.isActive === true
          )
        );
        setPDFData(res.data.results);
      })

      .catch((err) => {
        console.error(err);
      });
  };

  const handlePrint = async () => {
    if (!PDFData) return;
    generatePDF(PDFData);
  };

  const saveComment = () => {
    axiosInstance
      .post(`/api/CreateTakeDownComment`, {
        Comment: newComment.trim(),
        TakedownId: parseInt(id),
        CreatedBy: account?.name || "Title Company",
        ModifiedBy: account?.name || "Title Company",
        CreatedDateTime: new Date(),
        ModifiedDateTime: new Date(),
        isActive: true,
      })
      .then((res) => {
        if (res.status === 200 && (res?.data?.results ?? -1) > 0) {
          setNewComment("");
          loadDetails(id);
        } else {
          showSnackbar("There was an error creating a comment", "error");
        }
      });
  };

  const deleteLot = async (lotId: string) => {
    axiosInstance
      .post(`/api/DeleteTakedownLot/`, {
        id: lotId,
        modifiedBy: account?.name || "",
      })
      .then((res) => {
        loadDetails(id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loadJobNumbers = async () => {
    if (!formState.projectNumber && !formState.section) return;
    axiosInstance
      .get(
        `/api/GetJobNumbersbyProjectSection/${formState.projectNumber}/${formState.section}`
      )
      .then((res) => {
        setJobNumbers(
          res.data.results.map((job: string) => {
            return {
              text: job,
              value: job,
            };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!formState.section || !formState.projectNumber) return;
    loadJobNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.projectNumber, formState.section]);

  useEffect(() => {
    if (id) {
      loadDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const sortedAttachments = [...attachments].sort((a, b) => {
    // Convert the createdDatetime strings to Date objects for comparison
    const dateA = new Date(a.createdDatetime);
    const dateB = new Date(b.createdDatetime);
    //@ts-ignore
    return dateB - dateA; // This will sort the array in descending order
  });

  const sortedComments = [...comments].sort((a, b) => {
    // Convert the createdDatetime strings to Date objects for comparison
    const dateA = new Date(a.createdDatetime);
    const dateB = new Date(b.createdDatetime);

    //@ts-ignore
    return dateB - dateA; // This will sort the array in descending order
  });

  // Calculate pagination for comments
  const indexOfLastComment = currentCommentPage * itemsPerPage;
  const indexOfFirstComment = indexOfLastComment - itemsPerPage;
  const currentComments = sortedComments.slice(
    indexOfFirstComment,
    indexOfLastComment
  );
  const totalCommentPages = Math.ceil(sortedComments.length / itemsPerPage);

  // Calculate pagination for attachments
  const indexOfLastAttachment = currentAttachmentPage * itemsPerPage;
  const indexOfFirstAttachment = indexOfLastAttachment - itemsPerPage;
  const currentAttachments = sortedAttachments.slice(
    indexOfFirstAttachment,
    indexOfLastAttachment
  );
  const totalAttachmentPages = Math.ceil(
    sortedAttachments.length / itemsPerPage
  );

  const handleCommentPageChange = (pageNumber: number) => {
    setCurrentCommentPage(pageNumber);
  };

  const handleAttachmentPageChange = (pageNumber: number) => {
    setCurrentAttachmentPage(pageNumber);
  };

  const isGuaranteeFileNumberDirty =
    formState.guaranteeFileNumber !== initialValues.guaranteeFileNumber;
  const isStatusDirty = formState.status !== initialValues.status;

  const pages = [
    { name: "Edit Take Down", href: `/edit/${id}`, current: true },
  ];

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      handleFileUpload(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        showSnackbar(
          `Invalid file type: ${file.file.type || "unknown"}`,
          "error"
        );
      });
    },
    maxSize: 20 * 1024 * 1024, // 20MB
  });

  const handleFileUpload = async (file: File) => {
    const maxSizeInBytes = 20 * 1024 * 1024; // 20MB

    // Check file size
    if (file.size > maxSizeInBytes) {
      showSnackbar("File exceeds maximum size of 20MB", "error");
      return;
    }

    showSnackbar("Uploading Attachment", "info");

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosInstance.post(
        `/api/UploadAttachment?takedownId=${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("Attachment uploaded successfully", "success");
        // Refetch the data after successful upload
        await loadDetails(id);
      } else {
        showSnackbar("There was an error uploading the attachment", "error");
      }
    } catch (error) {
      console.error("Error uploading attachment:", error);
      showSnackbar(
        "An unexpected error occurred while uploading the attachment",
        "error"
      );
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-8 mt-4">
      <nav aria-label="Breadcrumb" className="flex">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                />
                <a
                  href={page.href}
                  aria-current={page.current ? "page" : undefined}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {page.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div className="mt-4 flex flex-col items-center">
        {/* Header */}
        <div className="border-b border-gray-200 pb-5 mb-5 w-full">
          <div className="mt-4 flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight mb-4 sm:mb-0">
              Edit Lot Take Down{" "}
              {formState.projectName && `  (${id} ${formState.projectName})`}
            </h1>
            <div className="flex flex-col sm:flex-row sm:items-center">
              <button
                type="button"
                onClick={handlePrint}
                className="mb-2 sm:mb-0 sm:mr-3 inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <PrintIcon className="h-5 w-5 mr-2" />
                Print
              </button>
              <button
                type="button"
                onClick={updateTakedown}
                disabled={
                  loading || (!isGuaranteeFileNumberDirty && !isStatusDirty)
                }
                className={`inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 ${
                  loading || (!isGuaranteeFileNumberDirty && !isStatusDirty)
                    ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                    : "bg-primary-500 text-white hover:brightness-150"
                }`}
              >
                {loading ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </div>
        </div>

        <div className="space-y-4 md:space-y-12">
          {/* Project Details Section */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-200 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
                Project Details
              </h2>
              <p className="text-left mt-1 text-sm leading-6 text-gray-600">
                Enter the details for the lot take down.
              </p>
            </div>

            <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-2 md:gap-y-8 sm:grid-cols-6 md:col-span-2">
              {/* Region */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="region"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Region
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="region"
                    name="region"
                    value={formState.region}
                    disabled
                    className="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Type */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Type
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="type"
                    name="type"
                    value={formState.type}
                    disabled
                    className="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Project Name */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="projectName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Project Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="projectName"
                    name="projectName"
                    value={formState.projectName}
                    disabled
                    className="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Project Number */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="projectNumber"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Project Number
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="projectNumber"
                    name="projectNumber"
                    value={formState.projectNumber}
                    disabled
                    className="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Section */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="section"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Section
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="section"
                    name="section"
                    value={formState.section}
                    disabled
                    className="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Brix Project ID */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="section"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Brix Project ID
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="brixProjectId"
                    name="brixProjectId"
                    value={formState.brixProjectId}
                    disabled
                    className="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* GF Number */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="guaranteeFileNumber"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  GF Number
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="guaranteeFileNumber"
                    name="guaranteeFileNumber"
                    value={formState.guaranteeFileNumber}
                    onChange={(e) => {
                      const newValue = e.target.value.toUpperCase();
                      if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                        setFormState({
                          ...formState,
                          guaranteeFileNumber: newValue,
                        });
                      }
                    }}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* Status */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Status
                </label>
                <div className="mt-2">
                  <select
                    id="status"
                    name="status"
                    value={formState.status}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        status: e.target.value,
                      })
                    }
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  >
                    {statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Addresses Section */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-200 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
                Addresses
              </h2>
              <p className="text-left mt-1 text-sm leading-6 text-gray-600">
                Edit and manage the addresses for the lot take down.
              </p>
            </div>

            <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 md:col-span-2">
              {/* Addresses Accordion */}
              <div className="col-span-full">
                <div className="border rounded-md">
                  <button
                    type="button"
                    className="w-full flex items-center justify-between px-4 py-2 text-left text-sm font-medium text-gray-900 bg-gray-50 hover:bg-gray-100 focus:outline-none"
                  >
                    <span>Addresses ({formState.addresses.length})</span>
                    <span>-</span>
                  </button>
                  <div className="px-4 py-2">
                    {formState?.addresses?.length > 0 ? (
                      formState.addresses.map((address, index) => (
                        <div
                          key={index}
                          className="grid grid-cols-1 gap-4 sm:grid-cols-12 mt-2 items-end"
                        >
                          <div className="sm:col-span-4 relative">
                            <label className="block text-sm font-medium text-gray-900">
                              Address
                            </label>
                            <div className="flex items-center">
                              <input
                                type="text"
                                value={address.address}
                                disabled
                                className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                              />
                              <button
                                type="button"
                                className="text-red-600 hover:text-red-800 p-2 absolute right-0 top-7"
                                onClick={() => deleteLot(address.id)}
                              >
                                <TrashIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                                <span className="sr-only">Remove address</span>
                              </button>
                            </div>
                          </div>
                          <div className="sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-900">
                              Job
                            </label>
                            <input
                              type="text"
                              value={address.jobNumber}
                              disabled
                              className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-900">
                              Lot
                            </label>
                            <input
                              type="text"
                              value={address.lot}
                              disabled
                              className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-900">
                              Block
                            </label>
                            <input
                              type="text"
                              value={address.block}
                              disabled
                              className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-900">
                              Price
                            </label>
                            <input
                              type="text"
                              value={address.price}
                              disabled
                              className="mt-1 block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="p-4">
                        <p className="text-gray-500 mt-2">
                          No addresses added yet.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Add New Address */}
              <div className="col-span-full">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-7">
                  {/* Address */}
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="newAddress"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      id="newAddress"
                      name="address"
                      placeholder="Address"
                      value={addressState.address}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue.length > 50) {
                          setShowMaxCharWarning(true);
                        } else {
                          setShowMaxCharWarning(false);
                          setAddressState({
                            ...addressState,
                            address: newValue,
                          });
                        }
                      }}
                      className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  {/* Job Number */}
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="jobNumber"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Job
                    </label>
                    <select
                      id="jobNumber"
                      name="jobNumber"
                      value={addressState.jobNumber}
                      onChange={(e) =>
                        setAddressState({
                          ...addressState,
                          jobNumber: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    >
                      <option value="">Job</option>
                      {jobNumbers.map((job) => (
                        <option key={job.value} value={job.value}>
                          {job.text}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Lot */}
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="lot"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Lot
                    </label>
                    <input
                      type="text"
                      id="lot"
                      name="lot"
                      placeholder="Lot"
                      value={addressState.lot}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue.length > 5) {
                          setShowMaxCharWarning(true);
                        } else {
                          setShowMaxCharWarning(false);
                          if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                            setAddressState({
                              ...addressState,
                              lot: newValue,
                            });
                          }
                        }
                      }}
                      className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  {/* Block */}
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="block"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Block
                    </label>
                    <input
                      type="text"
                      id="block"
                      name="block"
                      placeholder="Block"
                      value={addressState.block}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue.length > 5) {
                          setShowMaxCharWarning(true);
                        } else {
                          setShowMaxCharWarning(false);
                          if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                            setAddressState({
                              ...addressState,
                              block: newValue,
                            });
                          }
                        }
                      }}
                      className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  {/* Price */}
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Price
                    </label>
                    <input
                      type="text"
                      id="price"
                      name="price"
                      placeholder="Price"
                      value={addressState.price}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (newValue.length > 10) {
                          setShowMaxCharWarning(true);
                        } else {
                          setShowMaxCharWarning(false);
                          const decimalRegex = /^\d*(\.\d{0,2})?$/;
                          if (newValue.startsWith(".")) {
                            newValue = "0" + newValue;
                          }
                          if (newValue === "" || decimalRegex.test(newValue)) {
                            setAddressState({
                              ...addressState,
                              price: newValue,
                            });
                          }
                        }
                      }}
                      className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  {/* Add Button */}
                  <div className="sm:col-span-1 flex items-end">
                    <button
                      type="button"
                      className="w-full h-[38px] inline-flex items-center justify-center rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={async () => {
                        const lotsPromise = await axiosInstance.post(
                          `/api/UpdateTakedownLots`,
                          [
                            ...modifyArray([
                              ...formState.addresses,
                              {
                                id: "NEW_ADDRESS",
                                takedownId: parseInt(id),
                                address: addressState.address.trim(),
                                lot: addressState.lot.trim(),
                                block: addressState.block.trim(),
                                price: addressState.price.trim(),
                                modifiedBy: account?.name || "",
                                jobNumber: addressState.jobNumber,
                              },
                            ]),
                          ]
                        );
                        if (lotsPromise.status === 200) {
                          showSnackbar(
                            "Takedown lot added successfully",
                            "success"
                          );
                          loadDetails(id);
                        }
                        setShowMaxCharWarning(false);
                        setAddressState({
                          ...addressState,
                          address: "",
                          lot: "",
                          block: "",
                          price: "",
                          jobNumber: "",
                        });
                      }}
                      disabled={
                        !addressState.address.trim() ||
                        !addressState.lot.trim() ||
                        !addressState.price.trim() ||
                        !addressState.jobNumber.trim()
                      }
                    >
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                {showMaxCharWarning && (
                  <div className="mt-2">
                    <p className="text-sm text-red-600">
                      Address must be 50 characters or less. Lot and Block must
                      be 5 characters or less. Price must be 10 characters or
                      less.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Additional Information Section */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
                Additional Information
              </h2>
              <p className="text-left mt-1 text-sm leading-6 text-gray-600">
                Add comments and attachments related to the lot take down.
              </p>
            </div>

            <div className="max-w-3xl md:col-span-2">
              {/* Tabs for Comments and Attachments */}
              <div>
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    <button
                      type="button"
                      onClick={() => setActiveTab("Comments")}
                      className={classNames(
                        activeTab === "Comments"
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                    >
                      Comments
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab("Attachments")}
                      className={classNames(
                        activeTab === "Attachments"
                          ? "border-primary-500 text-primary-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                    >
                      Attachments
                    </button>
                  </nav>
                </div>
                <div className="mt-4">
                  {activeTab === "Comments" && (
                    <div className="bg-white py-8 lg:py-16 antialiased">
                      <div className="max-w-3xl mx-auto px-4">
                        <div className="flex justify-between items-center mb-6">
                          <h2 className="text-lg lg:text-2xl font-bold text-gray-900">
                            Comments ({comments.length})
                          </h2>
                        </div>

                        {/* Comment input form */}
                        <div className="mb-6">
                          <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 ">
                            <label htmlFor="comment" className="sr-only">
                              Your comment
                            </label>
                            <textarea
                              id="comment"
                              rows={6}
                              className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none"
                              placeholder="Write a comment..."
                              value={newComment}
                              onChange={(e) => setNewComment(e.target.value)}
                              required
                            ></textarea>
                          </div>
                          <div className="flex justify-end">
                            <button
                              type="button"
                              onClick={addComment}
                              className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-500 rounded-lg focus:ring-4 focus:ring-primary-200  hover:brightness-150"
                            >
                              Post comment
                            </button>
                          </div>
                        </div>

                        {/* Comments list */}
                        {currentComments.map((comment, index) => (
                          <article
                            key={index}
                            className="p-6 mb-3 text-base bg-white border-t border-gray-200"
                          >
                            <footer className="flex justify-between items-center mb-2">
                              <div className="flex items-center w-full">
                                <p className="inline-flex items-center mr-3 text-sm text-gray-900 font-semibold break-all">
                                  {comment?.createdBy || "Title Company"}
                                </p>
                                <p className="text-sm text-right w-full text-gray-600">
                                  <time
                                    className="text-right w-full"
                                    dateTime={comment.createdDatetime}
                                    title={new Date(
                                      comment.createdDatetime
                                    ).toLocaleDateString()}
                                  >
                                    {new Date(
                                      comment.createdDatetime
                                    ).toLocaleDateString()}
                                  </time>
                                </p>
                              </div>
                            </footer>
                            <p className="text-gray-500 text-left w-full break-all">
                              {comment.comment}
                            </p>
                          </article>
                        ))}

                        {/* Pagination */}
                        {totalCommentPages > 1 && (
                          <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-6">
                            <div className="-mt-px flex w-0 flex-1">
                              <button
                                onClick={() =>
                                  handleCommentPageChange(
                                    currentCommentPage - 1
                                  )
                                }
                                disabled={currentCommentPage === 1}
                                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
                              >
                                <ChevronLeftIcon
                                  className="mr-3 h-5 w-5"
                                  aria-hidden="true"
                                />
                                Previous
                              </button>
                            </div>
                            <div className="hidden md:-mt-px md:flex">
                              {[...Array(totalCommentPages)].map((_, index) => {
                                const pageNumber = index + 1;
                                if (
                                  pageNumber === 1 ||
                                  pageNumber === totalCommentPages ||
                                  (pageNumber >= currentCommentPage - 1 &&
                                    pageNumber <= currentCommentPage + 1)
                                ) {
                                  return (
                                    <button
                                      key={index}
                                      onClick={() =>
                                        handleCommentPageChange(pageNumber)
                                      }
                                      className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                                        currentCommentPage === pageNumber
                                          ? "border-primary-500 text-primary-600"
                                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                      }`}
                                    >
                                      {pageNumber}
                                    </button>
                                  );
                                } else if (
                                  (pageNumber === currentCommentPage - 2 &&
                                    pageNumber > 2) ||
                                  (pageNumber === currentCommentPage + 2 &&
                                    pageNumber < totalCommentPages - 1)
                                ) {
                                  return (
                                    <span
                                      key={index}
                                      className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
                                    >
                                      ...
                                    </span>
                                  );
                                }
                                return null;
                              })}
                            </div>
                            <div className="-mt-px flex w-0 flex-1 justify-end">
                              <button
                                onClick={() =>
                                  handleCommentPageChange(
                                    currentCommentPage + 1
                                  )
                                }
                                disabled={
                                  currentCommentPage === totalCommentPages
                                }
                                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
                              >
                                Next
                                <ChevronRightIcon
                                  className="ml-3 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </nav>
                        )}
                      </div>
                    </div>
                  )}
                  {activeTab === "Attachments" && (
                    <div className="bg-white py-8 lg:py-16 antialiased">
                      <div className="max-w-3xl mx-auto px-4">
                        <div className="flex justify-between items-center mb-6">
                          <h2 className="text-lg lg:text-2xl font-bold text-gray-900">
                            Attachments ({attachments.length})
                          </h2>
                        </div>

                        {/* File drop area */}
                        <div {...getRootProps()} className="mb-6">
                          <input {...getInputProps()} />
                          <div className="py-8 px-4 border-2 border-dashed border-gray-300 rounded-lg text-center cursor-pointer hover:border-primary-500 transition-colors">
                            {isDragActive ? (
                              <p className="text-primary-500">
                                Drop the files here ...
                              </p>
                            ) : (
                              <p className="text-gray-500">
                                Drag 'n' drop some files here, or click to
                                select files
                              </p>
                            )}
                          </div>
                        </div>

                        {/* Attachment list */}
                        {currentAttachments.map((attachment, index) => (
                          <article
                            key={index}
                            className="p-6 mb-3 text-base bg-white border-t border-gray-200"
                          >
                            <footer className="flex justify-between items-center mb-2">
                              <div className="flex items-center">
                                <p className="inline-flex items-center mr-3 text-sm text-gray-900 font-semibold">
                                  <UserCircleIcon
                                    className="mr-2 w-6 h-6 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  {account?.username || "Anonymous"}
                                </p>
                                <p className="text-sm text-gray-600">
                                  <time
                                    dateTime={new Date(
                                      attachment.modifiedDatetime
                                    ).toISOString()}
                                  >
                                    {new Date(
                                      attachment.modifiedDatetime
                                    ).toLocaleDateString()}
                                  </time>
                                </p>
                              </div>
                            </footer>
                            <div className="flex items-center mt-2">
                              <PaperClipIcon className="w-5 h-5 mr-2 text-gray-500" />
                              <p className="text-gray-900 font-medium">
                                {attachment.attachmentName}
                              </p>
                            </div>
                          </article>
                        ))}

                        {attachments.length === 0 && (
                          <p className="text-gray-500 text-center">
                            No attachments yet
                          </p>
                        )}

                        {/* Pagination for attachments */}
                        {totalAttachmentPages > 1 && (
                          <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-6">
                            <div className="-mt-px flex w-0 flex-1">
                              <button
                                onClick={() =>
                                  handleAttachmentPageChange(
                                    currentAttachmentPage - 1
                                  )
                                }
                                disabled={currentAttachmentPage === 1}
                                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
                              >
                                <ChevronLeftIcon
                                  className="mr-3 h-5 w-5"
                                  aria-hidden="true"
                                />
                                Previous
                              </button>
                            </div>
                            <div className="hidden md:-mt-px md:flex">
                              {[...Array(totalAttachmentPages)].map(
                                (_, index) => {
                                  const pageNumber = index + 1;
                                  if (
                                    pageNumber === 1 ||
                                    pageNumber === totalAttachmentPages ||
                                    (pageNumber >= currentAttachmentPage - 1 &&
                                      pageNumber <= currentAttachmentPage + 1)
                                  ) {
                                    return (
                                      <button
                                        key={index}
                                        onClick={() =>
                                          handleAttachmentPageChange(pageNumber)
                                        }
                                        className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                                          currentAttachmentPage === pageNumber
                                            ? "border-primary-500 text-primary-600"
                                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                        }`}
                                      >
                                        {pageNumber}
                                      </button>
                                    );
                                  } else if (
                                    (pageNumber === currentAttachmentPage - 2 &&
                                      pageNumber > 2) ||
                                    (pageNumber === currentAttachmentPage + 2 &&
                                      pageNumber < totalAttachmentPages - 1)
                                  ) {
                                    return (
                                      <span
                                        key={index}
                                        className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
                                      >
                                        ...
                                      </span>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </div>
                            <div className="-mt-px flex w-0 flex-1 justify-end">
                              <button
                                onClick={() =>
                                  handleAttachmentPageChange(
                                    currentAttachmentPage + 1
                                  )
                                }
                                disabled={
                                  currentAttachmentPage === totalAttachmentPages
                                }
                                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 disabled:opacity-50"
                              >
                                Next
                                <ChevronRightIcon
                                  className="ml-3 h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </nav>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Comment and Attachment Input */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
