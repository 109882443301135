import jsPDF from "jspdf";

export const generatePDF = (data) => {
  const doc = new jsPDF();

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 10;
  const lineHeight = 12; // Reduced from 14
  const smallerLineHeight = 8; // Added for compact sections
  const bottomMargin = 15; // Reduced from 20
  let yPos = 25; // Reduced initial spacing
  let currentPage = 1;

  // Check if we need a new page
  const checkPageBreak = (heightNeeded) => {
    if (yPos + heightNeeded >= pageHeight - bottomMargin) {
      doc.addPage();
      currentPage++;
      yPos = 20; // Reduced top margin on subsequent pages
      return true;
    }
    return false;
  };

  // Helper functions
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? dateString
      : date.toLocaleString("en-US", {
          timeZone: "UTC",
          hour12: true,
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });
  };

  const formatCurrency = (amount) => {
    if (isNaN(amount)) return String(amount);
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  // Helper function to add text fields
  const addTextField = (x, y, title, value) => {
    doc.setFontSize(9); // Reduced from 10
    doc.text(title, x, y);
    doc.setFontSize(10); // Reduced from 12
    doc.text(`${value}`, x, y + 5); // Reduced from 6
    doc.setDrawColor(0);
    doc.setLineWidth(0.2);
    doc.line(x, y + 6, x + 80, y + 6); // Reduced from 7
  };

  // Add title
  doc.setFontSize(14); // Reduced from 15
  doc.text("Cash Lot and Loan Log", pageWidth / 2, 10, { align: "center" });

  // Add subdivision and company
  const subdivision = `${data.projectName} SEC ${data.section}`.toUpperCase();
  const company = `Highland-${data.region}`;

  addTextField(margin, yPos, "Subdivision:", subdivision);
  addTextField(pageWidth / 2, yPos, "Company:", company);

  yPos += lineHeight;

  // Add dates
  if (data.createdDatetime) {
    addTextField(
      margin,
      yPos,
      "Date Ordered:",
      formatDateTime(data.createdDatetime)
    );
  }
  if (data.modifiedDatetime) {
    addTextField(
      pageWidth / 2,
      yPos,
      "Title Work:",
      formatDateTime(data.modifiedDatetime)
    );
  }

  yPos += lineHeight;

  // Add GF#
  if (data.guaranteeFileNumber) {
    addTextField(margin, yPos, "GF #:", data.guaranteeFileNumber);
  }

  // Add takedown lots table if present
  if (data.takedownLots?.length > 0) {
    yPos += lineHeight;

    // Check if we need a new page for the table
    const estimatedTableHeight = (data.takedownLots.length + 1) * lineHeight;
    checkPageBreak(estimatedTableHeight);

    const tableHeaders = ["Address", "Proj/Job", "Price", "Lot/Block"];
    const colWidths = [80, 40, 40, 40];
    let xPos = margin;

    // Draw headers
    doc.setFontSize(9);
    doc.setFont(undefined, "bold");
    tableHeaders.forEach((header, i) => {
      doc.text(header, xPos, yPos);
      xPos += colWidths[i];
    });
    doc.setFont(undefined, "normal");

    yPos += smallerLineHeight;

    // Draw rows
    data.takedownLots.forEach((lot) => {
      if (lot.isActive) {
        if (checkPageBreak(smallerLineHeight)) {
          // Redraw headers on new page
          xPos = margin;
          doc.setFontSize(9);
          doc.setFont(undefined, "bold");
          tableHeaders.forEach((header, i) => {
            doc.text(header, xPos, yPos);
            xPos += colWidths[i];
          });
          doc.setFont(undefined, "normal");
          yPos += smallerLineHeight;
        }

        xPos = margin;
        const rowData = [
          lot.addressStreet?.toUpperCase() || "",
          `${data.projectNumber}-${lot.jobNumber}`,
          formatCurrency(lot.price),
          `${lot.lot || ""}/${lot.block || ""}`,
        ];

        rowData.forEach((cell, i) => {
          doc.text(cell, xPos, yPos);
          xPos += colWidths[i];
        });
        yPos += smallerLineHeight;
      }
    });
  }

  // Add comments if present
  if (data.takedownComments?.length > 0) {
    yPos += smallerLineHeight;
    checkPageBreak(lineHeight);

    doc.setFontSize(11);
    doc.setFont(undefined, "bold");
    doc.text("Comments", margin, yPos);
    doc.setFont(undefined, "normal");

    data.takedownComments.forEach((comment) => {
      if (comment.isActive) {
        const commentText = comment.comment?.trim();
        if (!commentText) return; // Skip empty comments

        const commentHeight = smallerLineHeight * 2;
        if (checkPageBreak(commentHeight)) {
          doc.setFontSize(11);
          doc.setFont(undefined, "bold");
          doc.text("Comments (continued)", margin, yPos);
          doc.setFont(undefined, "normal");
          yPos += smallerLineHeight;
        }

        yPos += smallerLineHeight;

        // Combine datetime with createdBy for more compact display
        doc.setFontSize(8);
        const commentHeader = `${comment.createdBy} - ${formatDateTime(
          comment.createdDatetime
        )}`;
        doc.text(commentHeader, margin, yPos);

        // Add comment text with proper word wrapping
        yPos += smallerLineHeight - 2;
        doc.setFontSize(9);
        const splitText = doc.splitTextToSize(
          commentText,
          pageWidth - margin * 2
        );
        doc.text(splitText, margin, yPos);
        yPos += splitText.length * (smallerLineHeight - 2);
      }
    });
  }

  // Add history section
  checkPageBreak(lineHeight * 5);
  yPos += smallerLineHeight;
  doc.setFontSize(11);
  doc.setFont(undefined, "bold");
  doc.text("History", margin, yPos);
  doc.setFont(undefined, "normal");

  const historyItems = [
    { key: "Last Edited By", value: data.modifiedBy || "N/A" },
    {
      key: "Last Edited",
      value: formatDateTime(data.modifiedDatetime) || "N/A",
    },
    { key: "Created By", value: data.createdBy || "N/A" },
    { key: "Created", value: formatDateTime(data.createdDatetime) || "N/A" },
  ];

  historyItems.forEach((item) => {
    yPos += smallerLineHeight;
    if (checkPageBreak(smallerLineHeight)) {
      doc.setFontSize(11);
      doc.setFont(undefined, "bold");
      doc.text("History (continued)", margin, yPos);
      doc.setFont(undefined, "normal");
      yPos += smallerLineHeight;
    }
    addTextField(margin, yPos, item.key, item.value);
  });

  // Add page numbers
  const totalPages = doc.internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.setFontSize(8);
    doc.text(
      `Page ${i} of ${totalPages}`,
      pageWidth - margin,
      pageHeight - margin,
      { align: "right" }
    );
  }

  // Save the PDF
  doc.save(`${data.id}-${data.projectName}.pdf`);
};
