import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  Link,
  SelectChangeEvent,
} from "@mui/material";
import axiosInstance from "../../utils/axiosInstance";
import NoRows from "../../components/DataGrid/animations/NoRows";
import {
  MagnifyingGlassIcon as SearchIcon,
  PrinterIcon as PrintIcon,
  PencilSquareIcon as EditIcon,
  ArrowPathIcon,
  XMarkIcon as ClearIcon,
} from "@heroicons/react/20/solid";
import Comments from "../../modals/Comments";
import Attachments from "../../modals/Attachments";
import EditableGFNumber from "../../components/Buttons/EditGFNumber";
import EditableStatus from "../../components/Buttons/EditStatus";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSnackbarContext } from "../../providers/SnackbarProvider";
import { generatePDF } from "../../utils/generatePDF";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import NoResults from "../../components/DataGrid/animations/NoResults";

const theme = createTheme();

type FilterItem = {
  id?: string | number;
  field: string;
  operator: string;
  value: string | string[]; // Allow value to be string or array of strings
};

type Filters = {
  items: FilterItem[];
  quickFilterValues: string[];
};

type Project = {
  projectId: string;
  projectName: string;
  projectNumber: string;
};

const defaultFilter: Filters = {
  items: [
    {
      id: "status-multi",
      field: "status",
      operator: "isAnyOf",
      value: ["Received", "Received with errors", "Not received yet"],
    },
  ],
  quickFilterValues: [""],
};

export default function Home() {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const { showSnackbar } = useSnackbarContext();
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [originalDocuments, setOriginalDocuments] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [availableRegions, setAvailableRegions] = React.useState<string[]>([
    "All",
  ]);
  const [filt, setFilt] = React.useState<Filters>(defaultFilter);
  const [projectName, setProjectName] = React.useState<string>("All");
  const [region, setRegion] = React.useState<string>("All");
  const [type, setType] = React.useState<string>("All");
  const [status, setStatus] = React.useState<string>("Active");
  const [gridHeight, setGridHeight] = useState("500px");

  const loadTakeDowns = () => {
    axiosInstance
      .get(`/api/GetTakeDownReport`)
      .then((res) => {
        const formattedDocuments = [
          ...formatParents(res.data.results),
          ...flattenData(res.data.results),
        ];
        setOriginalDocuments(formattedDocuments);
        setDocuments(formattedDocuments);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const formatParents = (data: any) => {
    return data.map((item: any) => {
      return {
        id: `${item.id}`,
        combinedField: `${item.id} - ${item.projectName}`,
        status: item.status,
        region: item.region,
        projectName: item.projectName,
        section: item.section,
        type: item.type,
        guaranteeFileNumber: item.guaranteeFileNumber,
        hierarchy: [`${item.id} - ${item.projectName}`],
      };
    });
  };

  const flattenData = (data: any) => {
    const flattened: any = [];

    data.forEach((project: any) => {
      const parentId = `${project.id}`;
      const parentCombinedField = `${project.id} - ${project.projectName}`;

      project.takedownLots.forEach((takedownLot: any) => {
        const childCombinedField = `${takedownLot.id}/${takedownLot.addressStreet}`;
        const newItem = {
          ...takedownLot,
          id: `${takedownLot.id}/${takedownLot.addressStreet}`,
          hierarchy: [parentCombinedField, childCombinedField],
          status: project.status,
          region: project.region,
          projectName: project.projectName,
          section: project.section,
          type: project.type,
          parentId: parentId,
          childId: takedownLot.id,
          isActive: takedownLot.isActive,
          combinedField: `${parentCombinedField} ${childCombinedField}`, // Include combinedField
        };
        if (newItem.isActive === true) {
          flattened.push(newItem);
        }
      });
    });
    return flattened;
  };

  const updateFilter = (field: string, value: string | string[]): void => {
    setFilt((prevFilt) => {
      const otherItems = prevFilt.items.filter((item) => item.field !== field);

      if (value === "All") {
        return {
          ...prevFilt,
          items: otherItems,
        };
      }

      const newFilter = {
        id: `${field}-${Array.isArray(value) ? "multi" : value}`,
        field,
        operator: Array.isArray(value) ? "isAnyOf" : "equals",
        value,
      };

      return {
        ...prevFilt,
        items: [...otherItems, newFilter],
      };
    });

    console.log(`Updating filter for ${field} with value:`, value);
  };

  // Event handlers
  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setType(newValue);
    updateFilter("type", newValue);
  };

  const handleRegionChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setRegion(newValue);
    updateFilter("region", newValue);
  };

  const handleProjectNameChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setProjectName(newValue);
    updateFilter("projectName", newValue);
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setStatus(newValue);

    if (newValue === "Closed") {
      updateFilter("status", "Closed");
    } else {
      // Pass an array of values for the 'Active' status
      updateFilter("status", [
        "Received",
        "Received with errors",
        "Not received yet",
      ]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFilt((prevFilt) => ({
      ...prevFilt,
      quickFilterValues: [newValue],
    }));
  };

  const updateGFNumber = async (takedownId: string, newGFNumber: string) => {
    const response = await axiosInstance.post(`/api/UpdateTakedown`, {
      id: parseInt(takedownId),
      guaranteeFileNumber: newGFNumber,
      modifiedBy: account?.name || "",
    });
    if (response.status === 200) {
      showSnackbar("GF # updated successfully", "info");
    } else {
      showSnackbar("There was an issue updating the GF #.", "error");
    }
  };

  const updateStatus = async (takedownId: string, newStatusValue: number) => {
    const response = await axiosInstance.post(`/api/UpdateTakedown`, {
      id: parseInt(takedownId),
      titleStatusId: newStatusValue,
      modifiedBy: account?.name || "",
    });
    if (response.status === 200) {
      showSnackbar("Status updated successfully", "info");
    } else {
      showSnackbar("There was an issue updating the status.", "error");
    }
  };

  function getValueFromLabel(label: string | number) {
    const labelToValueMap = {
      "Not received yet": 1,
      "Received with errors": 3,
      Received: 2,
      Closed: 4,
    };

    // @ts-ignore
    return labelToValueMap[label] || null; // Returns null if the label does not match
  }

  const loadRegions = async () => {
    axiosInstance
      .get(`/api/getRegions`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const regions = res.data.results;
        setAvailableRegions(["All", ...regions]);
      })
      .catch((err) => {
        console.error(err);
        setAvailableRegions(["All", "DFW", "Houston", "Austin", "San Antonio"]);
      });
  };

  const loadProjects = async () => {
    if (region === "All") {
      // Use a set to keep track of unique project names
      const projectNamesSet: Set<string> = new Set();

      documents.forEach((project: any) => {
        projectNamesSet.add(project.projectName);
      });

      // Convert the set back into an array
      // @ts-ignore
      const uniqueProjectNames = [...projectNamesSet];
      setProjects(
        uniqueProjectNames.map((project) => ({
          projectName: project,
          projectId: project,
          projectNumber: project,
        }))
      );
      return;
    } // alert error somehow in the ui
    axiosInstance
      .get(`/api/GetProjectsbyRegion/${region}`)
      .then((res) => {
        setProjects(
          res.data.results.sort(
            (a: { projectName: string }, b: { projectName: any }) =>
              a.projectName.localeCompare(b.projectName)
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    loadRegions();
  }, []);

  React.useEffect(() => {
    loadTakeDowns();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, region]);

  useEffect(() => {
    if (!loading && documents.length > 0) {
      setGridHeight("auto");
    } else {
      setGridHeight("500px");
    }
  }, [loading, documents]);

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
    },
    { field: "region", headerName: "Region", minWidth: 150 },
    {
      field: "projectName",
      headerName: "Project Name",
      minWidth: 150,
      filterable: true,
    },
    {
      field: "section",
      headerName: "Section",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "guaranteeFileNumber",
      headerName: "GF #",
      minWidth: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.row.hierarchy.length <= 1 ? (
          <EditableGFNumber
            takedownId={params.id as any}
            gfNumber={params.value as any}
            onSave={updateGFNumber}
          />
        ) : null,
    },
    {
      field: "status",
      headerName: "Title Status",
      minWidth: 200,
      renderCell: (params) => {
        return params.row.hierarchy.length <= 1 ? (
          <EditableStatus
            takedownId={params.id as any}
            statusValue={getValueFromLabel(params.value)} // Current status value
            onUpdateStatus={updateStatus}
          />
        ) : null;
      },
    },
    {
      field: "combinedField",
      headerName: "Takedown Name",
      width: 0,
      maxWidth: 0,
      hideable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "right",
      align: "right",
      minWidth: 200,
      flex: 1,
      filterable: false,
      sortable: false,
      hideable: false,
      pinnable: false,
      renderCell: ({ row }) => {
        return (
          row.hierarchy.length <= 1 && (
            <div className="flex flex-row justify-end items-center space-x-1">
              <Tooltip title="Edit">
                <IconButton href={`/edit/${row.id}`}>
                  <EditIcon className="h-6 w-6 text-primary-500" />
                </IconButton>
              </Tooltip>
              <Comments takeDownID={row.id} />
              <Attachments takeDownID={row.id} />
              <Tooltip title="Print">
                <IconButton
                  onClick={() => {
                    if (!row.id) return;
                    axiosInstance
                      .get(`/api/GetTakedownDetails/${row.id}`)
                      .then((res) => {
                        generatePDF(res.data.results);
                      });
                  }}
                >
                  <PrintIcon className="h-6 w-6 text-primary-500" />
                </IconButton>
              </Tooltip>
            </div>
          )
        );
      },
    },
  ];

  const handleResetFilters = () => {
    setType("All");
    setRegion("All");
    setProjectName("All");
    setStatus("Active");
    setFilt(defaultFilter);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="p-4">
        <div className="h-full w-full mt-2">
          <div className="flex flex-row justify-between px-4 py-4">
            <div className="flex flex-row items-start space-x-4 w-3/4">
              <div className="w-full max-w-lg">
                <TextField
                  id="search-takedown"
                  size="small"
                  label="Search"
                  variant="outlined"
                  onChange={handleSearchChange}
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="info" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    maxWidth: "lg",
                    width: "100%",
                    "& .MuiInputBase-input": {
                      "&:focus": {
                        outline: "none",
                        boxShadow: "none",
                      },
                    },
                  }}
                />
              </div>
              <div className="w-1/3">
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={type}
                    label="Type"
                    size="small"
                    onChange={handleTypeChange}
                    classes={{
                      root: "border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500",
                    }}
                  >
                    {["All", "Interim", "Cash"].map((string, index) => (
                      <MenuItem key={index} value={string}>
                        {string}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="w-1/3">
                <FormControl fullWidth>
                  <InputLabel>Region</InputLabel>
                  <Select
                    value={region}
                    label="Region"
                    size="small"
                    onChange={handleRegionChange}
                    classes={{
                      root: "border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500",
                    }}
                  >
                    {availableRegions.map((string, index) => (
                      <MenuItem key={index} value={string}>
                        {string}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="w-1/3">
                <FormControl fullWidth>
                  <InputLabel>Project Name</InputLabel>
                  <Select
                    value={projectName}
                    label="Project Name"
                    size="small"
                    onChange={handleProjectNameChange}
                    classes={{
                      root: "border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500",
                    }}
                  >
                    <MenuItem key="All" value="All">
                      All
                    </MenuItem>
                    {projects
                      .sort((a, b) => a.projectName.localeCompare(b.projectName))
                      .map((string, index) => (
                        <MenuItem key={index} value={string.projectName}>
                          {string.projectName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="w-1/3">
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    label="Status"
                    size="small"
                    onChange={handleStatusChange}
                    classes={{
                      root: "border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500",
                    }}
                  >
                    <MenuItem key="Active" value="Active">
                      Active
                    </MenuItem>
                    <MenuItem key="Closed" value="Closed">
                      Closed
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="w-auto">
                <Tooltip title="Reset Filters">
                  <IconButton onClick={handleResetFilters} size="small">
                    <ArrowPathIcon className="h-6 w-6 text-gray-500" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className="flex flex-row items-center justify-end space-x-4 flex-1 mt-2 md:mt-0">
              <a
                id="create-takedown"
                href="/create"
                className="inline-flex items-center justify-center rounded-md px-4 py-2.5 text-sm font-semibold shadow-sm bg-primary-500 text-white hover:brightness-150 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
              >
                <PlusIcon className="w-5 h-5 mr-2" />
                Create
              </a>
            </div>
          </div>
          <div
            style={{
              height: gridHeight,
              width: "100%",
              minHeight: "500px",
              overflow: "auto",
            }}
          >
            <DataGridPro
              autoHeight
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              treeData
              getTreeDataPath={(row) => {
                return row.hierarchy || [];
              }}
              sx={{ mt: 1, minHeight: "80dvh" }}
              rows={documents}
              columns={columns}
              groupingColDef={{
                headerName: "Takedowns",
                sortable: false,
                align: "left",
                minWidth: 300,
                hideDescendantCount: true,
                valueFormatter: (params) => {
                  if (params.id && params.id.toString().split("/").length > 1) {
                    return params.id.toString().split("/")[1];
                  } else {
                    return (
                      <Tooltip title="Edit">
                        <Link color="inherit" href={`/edit/${params.id}`}>
                          {params.value}
                        </Link>
                      </Tooltip>
                    );
                  }
                },
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    combinedField: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              disableRowSelectionOnClick
              filterModel={filt}
              onFilterModelChange={(newFilterModel) =>
                setFilt(newFilterModel as any)
              }
              pagination
              slots={{
                noRowsOverlay: NoRows,
                noResultsOverlay: NoResults,
              }}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
